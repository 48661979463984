import React, {useState, useEffect} from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout"
import MentorCard from '../components/mentors/mentor_card'
import MentorModal from '../components/mentors/mentor_modal'
import "../components/mentors/mentors.css"

const MentorsPage = (props) => {
  const data = useStaticQuery(graphql`
  query {
    strapiMentorsPage {
      title
      text
    }
    allStrapiIndustry {
      nodes {
        id
        strapiId
        labelTextColor
        labelColor
        name
      }
    }
    allStrapiMentor(sort: {order: ASC, fields: name}, filter: {active: {eq: true}}) {
      nodes {
        active
        company
        id
        strapiId
        name
        linkedInUrl
        profilePic {
          localFile{
            publicURL
          }
        }
        modalPic {
          localFile{
            publicURL
          }
        }
        industry {
          id
          labelColor
          labelTextColor
          name
        }
        bio
        Mantra
      }
    }
  }
`)
  const pageInfo = data.strapiMentorsPage
  const industries = data.allStrapiIndustry.nodes
  const mentors = data.allStrapiMentor.nodes
  const [modalActive, setModalActive] = useState(false)
  const [currentMentor, setCurrentMentor] = useState(mentors[0])
  const [currentIndustry, setCurrentIndustry] = useState("All")
  const handleIndustryClick = (e)=>{
    const id = e.target.dataset.id
    setCurrentIndustry(id==="All"?id:parseInt(id))
  }
  const handleIndustrySelect=(e)=>{
    const id = e.target.value
    setCurrentIndustry(id==="All"?id:parseInt(id))
  }
  const handleMentorClick = (mentor)=>{
    setCurrentMentor(mentor)
    setModalActive(true)
  }
  const clearModalActive = ()=>{
    setModalActive(false)
  }
  useEffect(() => {
    const url = new URL(window.location.href)
    const mentorId = url.searchParams.get("mentor")
    const industryId = url.searchParams.get("industry")
    if(mentorId){
      const mentor = mentors.find((m)=>m.strapiId === parseInt(mentorId))
      if(mentor){
        setCurrentMentor(mentor)
        setModalActive(true)
      }
    } else if(industryId){
      setCurrentIndustry(parseInt(industryId))
    }
  }, [])
  return(
    <Layout language="english" path={props.path}>
      <SEO language="english" title="Mentors" />
      <MentorModal 
        clearModalActive={clearModalActive} 
        mentor={currentMentor} 
        active={modalActive}
        language="english"
      />
      <div className="mentors-top-section">
        <h1 className="page-title center">{pageInfo.title}</h1>
        <p>{pageInfo.text}</p>
        <div className="industry-filter-container">
          <button data-id="All" onClick={handleIndustryClick} className={`industry-button ${currentIndustry==="All"&&"active"}`}>All</button>
          {industries.map((i)=>{
            return(
              <button key={i.strapiId} data-id={i.strapiId} onClick={handleIndustryClick} className={`industry-button ${currentIndustry===i.strapiId&&"active"}`}>{i.name}</button>
            )
          })}
        </div>
        <div className="industry-filter-container-mobile">
          <p>INDUSTRY</p>
          <select onChange={handleIndustrySelect}>
            <option value="All">All</option>
            {industries.map((i)=>{
            return(
              <option key={`${i.strapiId}-option`} value={i.strapiId}>{i.name}</option>
            )
          })}
          </select>
        </div>
      </div>
      <div className="mentor-cards-section">
       {mentors.filter((m)=>currentIndustry==="All" || m.industry.id === currentIndustry).map((m)=>{
         return(
           <MentorCard language="english" handleMentorClick={handleMentorClick} mentor={m} key={m.id} />
         )
       })}
      </div>
    </Layout>
  )
}

export default MentorsPage